<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h3>Đơn hàng</h3>
      <b-button to="/order/add">
        Tạo đơn hàng mới
      </b-button>
    </div>
    <b-tabs class="cus-tabs">
      <b-tab
        class="cus-tab"
      >
        <template #title>
          <span @click="handleFilterStatus(null)">{{ $t('All') }}</span>
        </template>
      </b-tab>
      <b-tab
        v-for="(item, i) in statusOptions"
        :key="i"
        class="cus-tab"
      >
        <template #title>
          <span @click="handleFilterStatus(item.id)">{{ $t(item.name) }}</span>
        </template>
        <div>
          <!-- <OrderTable
            :items="item.items"
            :fields="fields"
            :add_new_button="false"
            :filter_payment_button="true"
            :filter_status_button="true"
            @handlerSort="handlerSort"
            @handlerDelete="handlerDelete"
            @handleFilter="handleFilter"
          /> -->
        </div>
      </b-tab>
      <OrderTable
        v-model="obOrder"
        :items="items"
        :fields="fields"
        :add_new_button="false"
        :filter_payment_button="true"
        :filter_status_button="true"
        @handlerSort="handlerSort"
        @handlerDelete="handlerDelete"
        @handleFilter="handleFilter"
        @SyncStatus="SyncStatus"
        @SyncOrder="SyncOrder"
        @handlerPrint="handlerPrint"
      />
    </b-tabs>
  </b-card>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
import {
  BTabs,
  BTab,
  BButton,
  // BDropdown,
  // BDropdownItem,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
// import statusList from '@/mixins/status.json'
import OrderTable from '../../components/custom-table/order-table.vue'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BCard,
    OrderTable,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'order_no', label: 'Order no', sortable: false },
        { key: 'created_at_utc', label: 'Created at', sortable: true },
        // { key: 'address', label: 'Address', sortable: true },
        { key: 'fullname', label: 'Fullname', sortable: true },
        { key: 'payment_method', label: 'Status', sortable: false },
        { key: 'totalF', label: 'Total', sortable: true },
        { key: 'payment_method', label: 'Payment Status', sortable: true },
        // { key: 'lang', label: 'Language', sortable: false },
        { key: 'more', label: 'Tool', sortable: false },
        // { key: 'sort', label: 'Reorder', sortable: false },
      ],
      tabReturnRefund: [
        {
          id: 7,
          name: 'Return',
          count: 0,
          note: 'Trạng thái đang chuyển hàng trả lại',
          items: [],
        },

        {
          id: 8,
          name: 'Refund',
          count: 0,
          note: 'Trạng thái đang đợi chuyển tiền lại cho seller',
          items: [],
        },

        {
          id: 9,
          name: 'Refund processed',
          count: 0,
          note: 'Trạng thái đã trả tiền lại cho seller',
          items: [],
        },
      ],
      tabList: [
        {
          id: 0,
          name: 'All',
          count: 0,
          note: 'All',
          items: [],
        },
        {
          id: 1,
          name: 'Mới',
          count: 0,
          note: 'Đợi xác nhận',
          items: [],
        },
        {
          id: 2,
          name: 'Xác nhận',
          count: 0,
          note: 'Chưa xử lý',
          items: [],
        },
        {
          id: 8,
          name: 'Đóng gói',
          count: 0,
          note: 'Kho đóng gói',
          items: [],
        },
        {
          id: 3,
          name: 'Chuyển sang vận chuyển',
          count: 0,
          note: 'Đã xử lý',
          items: [],
        },
        {
          id: 4,
          name: 'Đang vận chuyển',
          count: 0,
          note: 'Đang giao hàng',
          items: [],
        },
        {
          id: 5,
          name: 'Thành công',
          count: 0,
          note: 'Đã giao hàng xong, khách đã nhận được hàng',
          items: [],
        },
        {
          id: 6,
          name: 'Đơn huỷ',
          count: 0,
          note: 'Khách huỷ đơn hàng, khách được huỷ ở bước 1,2',
          items: [],
        },
        {
          id: 7,
          name: 'Hoàn trả',
          count: 0,
          note: 'Trạng thái đang chuyển hàng trả lại',
          items: [],
        },

        // {
        //   id: 8,
        //   name: 'Refund',
        //   count: 0,
        //   note: 'Trạng thái đang đợi chuyển tiền lại cho seller',
        //   items: [],
        // },

        // {
        //   id: 9,
        //   name: 'Refund processed',
        //   count: 0,
        //   note: 'Trạng thái đã trả tiền lại cho seller',
        //   items: [],
        // },
      ],
      items: [],
      totalRows: 20,
      statusOptions: [],
      tab: null,
      obOrder: {
        list: [],
        param: {
          total: 0,
          limit: 10,
          page: 1,
        },
      },
    }
  },
  watch: {
    '$route'() {
      this.loadList()
    },
    'obOrder.param': {
      // eslint-disable-next-line func-names, space-before-function-paren
      handler: function() {
        this.loadList()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getOrderStatus()
    this.loadList()
  },
  methods: {
    async getOrderStatus() {
      const res = await this.mixGet('/orders_status', { site_id: JSON.parse(localStorage.getItem('siteID')).id_number })
      if (res.status) {
        if (res.data) {
          this.statusOptions = res.data.items
        }
      }
    },
    async handlerSort(data) {
      const { item } = data
      const { method } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: item.item.id,
              position: Number(item.item.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/order/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handleFilter() {
      this.loadList()
    },
    handleFilterStatus(id) {
      this.tab = id
      this.loadList()
    },
    async SyncOrder(channel) {
      let api = ''
      if (channel === 'shopee') api = '/shopee/v2/sync_order?&date_from=2021/06/15&date_to=2021/06/30'
      if (channel === 'lazada') api = '/lazada/v2/sync_order?&date_from=2022-01-07&date_to=2022-09-07'
      if (channel === 'tiktok') api = '/tiktok/v2/sync_order'
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}${api}&site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Đồng bộ đơn hàng!',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Đồng bộ đơn hàng thành công',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async SyncStatus(channel) {
      let api = ''
      if (channel === 'shopee') api = '/shopee/v2/update_sync_status'
      if (channel === 'lazada') api = '/lazada/v2/update_sync_status'
      if (channel === 'tiktok') api = '/tiktok/v2/update_sync_status'
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}${api}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Đồng bộ trạng thái đơn hàng!',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Đồng bộ trạng thái đơn hàng thành công',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let params = ''
        if (this.$route.query.order_no) params += `&order_no=${this.$route.query.order_no}`
        if (this.$route.query.channel) params += `&type=${this.$route.query.channel}`
        if (this.$route.query.date_from) params += `&date_from=${this.$route.query.date_from}`
        if (this.$route.query.date_to) params += `&date_to=${this.$route.query.date_to}`
        if (this.tab) params += `&status=${this.tab}`

        // const res = await Request.get(
        //   this.$http,
        //   `${process.env.VUE_APP_API_URL}/orders?site_id=${siteId}${params}`,
        // )
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/orders_v2?site_id=${siteId}&limit=${this.obOrder.param.limit}&page=${this.obOrder.param.page}${params}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.totalRows = res.data.data.total
            const mapData = []
            res.data.data.items.map(val => {
              mapData.push({
                ...val,
                created_at_utc: val.created_at,
                status: this.statusOptions.filter(x => x.id === val.status)[0].name,
                status_no: this.statusOptions.filter(x => x.id === val.status)[0].id,
                payment_method: val.payment_type_text,
                totalF: Number(val.total) + Number(val.shipping_price) - Number(val.coupon_discount),
                shipping_order_code: this.getGHCode(val.param_ghn, val.param_ghtk),
              })
            })
            this.items = mapData
            this.obOrder.list = mapData
            this.obOrder.param.total = res.data.data.total
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    getGHCode(ghn, ghtk) {
      return ghn || ghtk
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/order/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Trademark has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async handlerPrint(data, status) {
      if (status === 1) {
        const res = await this.mixGet('/ghn/gen_token_order', { site_id: localStorage.getItem('siteID').id_number, order_id: data })
        if (res.status) {
          if (res.data.mode === 'sandbox') {
            const url = `https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          } else {
            const url = `https://online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=1400',
            )
          }
        }
      } else if (status === 2) {
        const res = await this.mixGet('/ghtk/gen_token_order', { site_id: localStorage.getItem('siteID').id_number, order_id: data })
        if (res.status) {
          if (res.data.mode === 'sandbox') {
            const url = `https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          } else {
            const url = `https://online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          }
        }
      }
    },
  },
}
</script>
<style lang="sass">
.cus-tabs
  .nav.nav-tabs
    border-bottom: 1px solid rgba(34, 41, 47, 0.125)
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)
    padding: 8px
    flex-wrap: nowrap
    white-space: nowrap
    overflow-x: auto
    &::-webkit-scrollbar
      display: none
    // width: fit-content
    // margin: auto
    .nav-link.active
      // background: var(--primary)
      // color: white
      // border-radius: 4px
</style>
